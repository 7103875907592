
import React from "react";
  
const AprendePick = () => {
  return (
    <div>
      <h1>Aprende a Usar Pick</h1>
    </div>
  );
};
  
export default AprendePick;
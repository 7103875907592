import React from "react";
import logo from '../pick-logo.png';
import '../App.css';

const Home = () => {
  return (
    <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
          ¡Bienvenidos a Pick!
          </p>
          <p><i>La mejor forma de organizar tus quinielas</i></p>
          {}
        </header>
    </div>
  );
};
  
export default Home;
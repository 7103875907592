
import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
    return (
      <nav className="NavBar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/aprende-pick">Aprende Pick</Link>
          </li>
          <li>
            <Link to="/contactus">Contáctanos</Link>
          </li>
        </ul>
      </nav>
    );
  }

export default NavBar;
import React from "react";
  
const ContactUs = () => {
  return (
    <div>
      <h1>Contáctanos</h1>
      <p>Para más información o soporte puedes contactarnos en: <a href="mailto:support@delvalon.com">support@delvalon.com</a></p>
    </div>
  );
};
  
export default ContactUs;